import { gql } from "@apollo/client";
import { CART_FRAGMENT } from "../fragments";

export const REMOVE_VOUCHER_CODE = gql`
  ${CART_FRAGMENT}

  mutation RemoveVoucherCode($couponCode: [String!]) {
    removeCoupons(input: { codes: $couponCode }) {
      cart {
        ...CartFragment
      }
    }
  }
`;
