import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "./product.fragment";
// TODO: DON"T TOUCH THIS FILE
export const CART_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}

  fragment CartFragment on Cart {
    total
    totalTax
    subtotal
    chosenShippingMethods
    shippingTax
    shippingTotal
    appliedCoupons {
      code
    }
    availableShippingMethods {
      packageDetails
      supportsShippingCalculator
      rates {
        id
        cost
        label
      }
    }
    contents(first: 0, last: 100) {
      products: nodes {
        quantity
        key
        extraData {
          key
          value
        }
        subtotal
        total
        tax
        subtotalTax
        quantity
        product {
          node {
            ...ProductFragment
            name
            databaseId
            productCategories {
              nodes {
                slug
              }
            }
            upsell {
              nodes {
                ...ProductFragment
                
              }
            }
          }
        }
      }
    }
  }
`;
