import { gql } from "@apollo/client";

// Define mutation
const UPDATE_SHIPPING_ADDRESS = gql`
    # Increments a back-end counter and gets its resulting value
    mutation UpdateShippingAddress($postCode: String!) {
        updateCustomer(input: { shipping: { postcode: $postCode } }) {
            customer {
                hasCalculatedShipping
            }
        }
    }
`;


export { UPDATE_SHIPPING_ADDRESS };

// Define mutation
const UPDATE_SHIPPING_METHODS = gql`
    # Increments a back-end counter and gets its resulting value
    mutation UpdateShipping($method: [String]) {
        updateShippingMethod(input: { shippingMethods: $method }) {
            cart {
                shippingTotal
                chosenShippingMethods
            }
        }
    }
`;

export { UPDATE_SHIPPING_METHODS };
