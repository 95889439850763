import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "../fragments";

const REMOVE_ITEM_FROM_CART = gql`
${PRODUCT_FRAGMENT}
     mutation hjkh($keys: [ID]) {
  removeItemsFromCart(input: {keys: $keys}) { 
     cart {
                total
                totalTax
                subtotal
                shippingTotal
                appliedCoupons {
                    code
                }
                availableShippingMethods {
                    packageDetails
                    supportsShippingCalculator
                    rates {
                        id
                        cost
                        label
                    }
                }
                contents {
                    products: nodes {
                        quantity
                        key
                        extraData {
                            key
                            value
                        }
                        subtotal
                        product {
                            node {
                                ...ProductFragment
                                upsell {
                                    nodes {
                                        ...ProductFragment
                                    }
                                }
                            }
                        }
                    }
                }
            }
        
  }
}
  `;
export { REMOVE_ITEM_FROM_CART };
