import { VALIDATE_ZIP } from "../mutations";
import { useMutation } from "@apollo/client";

type Result = {
  delivery_price: string;
  holidays: string[];
  working_days: string[];
  working_time: string[];
  id: string;
};

export const useCheckZip = (): (zip: string, canton: string) => Promise<Result> => {
  const [validateZip] = useMutation(VALIDATE_ZIP);
  return async (zip: string, canton: string): Promise<Result> => {
    try {
      const res = await validateZip({ variables: { zip, state: canton } });
      return res.data.validateZip.bakery;
    } catch (error) {
      return error.message;
    }
  };
};
