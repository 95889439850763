import React from "react";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import { HttpLink } from "apollo-link-http";
import { CartProvider } from "./src/context/cart.context";
import { ApolloLink } from "apollo-link";
import { ParallaxProvider } from "react-scroll-parallax";

import crossfetch from "cross-fetch";
import UserDataProvider from "./src/context/user-data.context";
import VoucherProvider from "./src/context/voucher-data.context";

const httpLink = new HttpLink({
  uri: "https://ru06wamdpv.preview.infomaniak.website/graphql",
  fetch: crossfetch,
  fetchOptions: {
    mode: "cors",
  },
  onError: (e) => {
    console.error(e);
  },
});

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
const middleware = new ApolloLink((operation, forward) => {
  /**
   * If session data exist in local storage, set value as session header.
   */

  const session = localStorage.getItem("woo-session");
  if (session) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        "woocommerce-session": `Session ${session}`,
      },
    }));
  }

  return forward(operation);
});

/**
 * Afterware operation
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
const afterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const session = headers.get("woocommerce-session");
    if (session) {
      if (localStorage.getItem("woo-session") !== session) {
        localStorage.setItem("woo-session", headers.get("woocommerce-session"));
      }
    }

    return response;
  });
});

const client = new ApolloClient({
  link: middleware.concat(afterware.concat(httpLink)),
  credentials: "include",
  cache: new InMemoryCache(),
});

export const wrapPageElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ParallaxProvider>
        <UserDataProvider>
          <VoucherProvider>
            <CartProvider>{element}</CartProvider>
          </VoucherProvider>
        </UserDataProvider>
      </ParallaxProvider>
    </ApolloProvider>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ParallaxProvider>
        <UserDataProvider>
          <VoucherProvider>
            <CartProvider>{element}</CartProvider>
          </VoucherProvider>
        </UserDataProvider>
      </ParallaxProvider>
    </ApolloProvider>
  );
};
