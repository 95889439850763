import { gql } from "@apollo/client";

const CHECKOUT = gql`
    mutation CheckoutMulti(
        $billingFirstName: String!
        $billingLastName: String!
        $billingEmail: String!
        $billingPhone: String!
        $billingAddress: String!
        $billingPostCode: String!
        $billingCity: String!
        $billingState: String!
        $shipping: [CustomerAddressInput] = []
        $metaData: [MetaDataInput]
        $customerNote: String!
    ) {
        checkoutMulti(
            input: {
                billing: {
                    address1: $billingAddress
                    city: $billingCity
                    country: CH
                    email: $billingEmail
                    firstName: $billingFirstName
                    lastName: $billingLastName
                    phone: $billingPhone
                    postcode: $billingPostCode
                    state: $billingState
                }
                paymentMethod: "payrexx"
                shipToDifferentAddress: true
                customerNote: $customerNote
                metaData: $metaData
                shipping: $shipping
            }
        ) {
            clientMutationId
            order {
                id
                orderKey
                refunds {
                    nodes {
                        amount
                    }
                }
                status
            }
            result
            redirect
        }
    }
`;

export { CHECKOUT };
