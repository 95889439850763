/**
 *   updateItemQuantities(input: {items: {quantity: 10, key: ""}})

 */

import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "../fragments";

// Define mutation
const UPDATE_CART_ITEM = gql`
    # Increments a back-end counter and gets its resulting value
    ${PRODUCT_FRAGMENT}

    mutation AddToCart($quantity: Int!, $key: ID!) {
        updateItemQuantities(input: { items: { quantity: $quantity, key: $key } }) {
            cart {
                total
                totalTax
                subtotal
                shippingTotal
                appliedCoupons {
                    code
                }
                availableShippingMethods {
                    packageDetails
                    supportsShippingCalculator
                    rates {
                        id
                        cost
                        label
                    }
                }
                contents {
                    products: nodes {
                        quantity
                        key
                        extraData {
                            key
                            value
                        }
                        subtotal
                        product {
                            node {
                                ...ProductFragment
                                upsell {
                                    nodes {
                                        ...ProductFragment
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export { UPDATE_CART_ITEM };
