import { gql } from "@apollo/client";

type MetaData = {
  key: string;
  value: string;
};

type Coupon = {
  metaData: MetaData[];
  dateExpiry: Date;
  code: string;
  amount: number;
};
const FIND_VOUCHER = gql`
  query FindVoucher($code: ID!) {
    coupon(idType: CODE, id: $code) {
      metaData {
        key
        value
      }
      dateExpiry
      code
      amount
    }
  }
`;

export { MetaData, Coupon, FIND_VOUCHER };
export default FIND_VOUCHER;
