import { gql } from "@apollo/client";
import { CART_FRAGMENT } from "../fragments";

export const APPLY_VOUCHER_CODE = gql`
  ${CART_FRAGMENT}

  mutation ApplyVoucherCode($couponCode: String!) {
    applyCoupon(input: { code: $couponCode }) {
      cart {
        ...CartFragment
      }
    }
  }
`;
