import { gql } from "@apollo/client";
import { CART_FRAGMENT } from "../fragments";

export const EMPTY_CART = gql`
  ${CART_FRAGMENT}

  mutation EmptyCart {
    emptyCart(input: { clearPersistentCart: true }) {
      cart {
        ...CartFragment
      }
    }
  }
`;
