import React, { useState, createContext, useContext, useEffect } from "react";


interface VoucherDataContextProps {
  getVoucherData: () => any;
  changeVoucherData: (value?: any) => void;
  voucherDataIsExist: boolean;
}

export const VoucherDataContext = createContext<VoucherDataContextProps>({
  getVoucherData: () => {},
  changeVoucherData: () => {},
  voucherDataIsExist: false
});

export const useVoucherData = () => {
  const context = React.useContext(VoucherDataContext);
  if (context === undefined) {
    throw new Error("useVoucherData must be used within a UserDataProvider");
  }
  return context;
};

const VoucherProvider = ({ children }) => {
  const [voucherData, setVoucherData] = useState(null);

  const changeVoucherData = (value?: any) =>
    value ? setVoucherData(value) : setVoucherData(null);
  const voucherDataIsExist = !!voucherData;
  const getVoucherData = () => voucherData;
  useEffect(() => {
    console.log("voucherData", voucherData);
  }, [voucherData]);

  const value = {
    getVoucherData,
    changeVoucherData,
    voucherDataIsExist
  };
  return (
    <VoucherDataContext.Provider value={value}>
      {children}
    </VoucherDataContext.Provider>
  );
};

export default VoucherProvider;
