import { CART_FRAGMENT } from "../fragments";
import { gql } from "@apollo/client";

interface Cart {
    total: string;
    totalTax: string;
    shippingTotal: string;
    appliedCoupons: { code: string }[];
    subtotal: string;
    contents: {
        products: {
            quantity: number;
            name: string;
            extraData: { key: string; value: string }[];
        }[];
    };
}

interface CartData {
    cart: Cart;
}

const GET_CART = gql`
    ${CART_FRAGMENT}
    query GetCart {
        cart(recalculateTotals: true) {
            ...CartFragment
        }
    }
`;

export { Cart, CartData, GET_CART };
export default GET_CART;
