// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bestellung-erhalten-tsx": () => import("./../../../src/pages/bestellung-erhalten.tsx" /* webpackChunkName: "component---src-pages-bestellung-erhalten-tsx" */),
  "component---src-pages-brunch-bestellen-tsx": () => import("./../../../src/pages/brunch-bestellen.tsx" /* webpackChunkName: "component---src-pages-brunch-bestellen-tsx" */),
  "component---src-pages-brunch-schenken-tsx": () => import("./../../../src/pages/brunch-schenken.tsx" /* webpackChunkName: "component---src-pages-brunch-schenken-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-templates-wp-page-template-tsx": () => import("./../../../src/templates/wp-page-template.tsx" /* webpackChunkName: "component---src-templates-wp-page-template-tsx" */),
  "component---src-templates-wp-product-template-tsx": () => import("./../../../src/templates/wp-product-template.tsx" /* webpackChunkName: "component---src-templates-wp-product-template-tsx" */)
}

