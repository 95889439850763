import { gql } from "@apollo/client";

export type TProductFragment = {
    id: string | number;
    purchasable: boolean;
    onSale: boolean;
    databaseId: string;
    productCategories: {
        nodes: {
            slug: string;
        }[];
    };
    slug: string;
    sku: string;
    name: string;
    description: string;
    shortDescription: string;
    attributes: {
        nodes: { name: string; options: string[]; label: string }[];
    };
    image: {
        sourceUrl: string;
        altText: string;
    };
    price: string;
};

export const PRODUCT_FRAGMENT = gql`
    fragment ProductFragment on BundleProduct {
        id
        purchasable
        databaseId
        onSale
        productCategories {
            nodes {
                slug
            }
        }
        slug
        sku
        name
        description(format: RAW)
        shortDescription(format: RAW)
        attributes {
            nodes {
                name
                options
                label
                variation
            }
        }
        image {
            sourceUrl
            altText
        }
        price(format: RAW)
        bundleItems {
            nodes {
                ... on SimpleProduct {
                    id
                    databaseId
                    image {
                        sourceUrl
                    }
                    name
                    slug
                    price
                }
            }
        }
    }
`;
