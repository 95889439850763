import { gql } from "@apollo/client";
import { PRODUCT_FRAGMENT } from "../fragments";

// Define mutation
const ADD_TO_CART = gql`
    # Increments a back-end counter and gets its resulting value
    ${PRODUCT_FRAGMENT}

    mutation AddToCart($productId: Int!, $quantity: Int!, $extraData: String!) {
        addToCart(input: { productId: $productId, extraData: $extraData, quantity: $quantity }) {
            cartItem {
                key
            }
            cart {
                total
                totalTax
                subtotal
                shippingTotal
                availableShippingMethods {
                    packageDetails
                    supportsShippingCalculator
                    rates {
                        id
                        cost
                        label
                    }
                }
                appliedCoupons {
                    code
                }
                contents {
                    products: nodes {
                        quantity
                        key
                        extraData {
                            key
                            value
                        }
                        subtotal
                        product {
                            node {
                                ...ProductFragment
                                upsell {
                                    nodes {
                                        ...ProductFragment
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export { ADD_TO_CART };

const ADD_BUNDLE_TO_CART = gql`
    # Increments a back-end counter and gets its resulting value

    mutation AddBundleToCart($items: [CartItemInput]) {
        addCartItems(input: { items: $items }) {
            cart {
                total
            }
        }
    }
`;

export { ADD_BUNDLE_TO_CART };
