import { gql } from "@apollo/client";

const VALIDATE_VOUCHER = gql`
  mutation validVoucher($code: String!) {
    validateVoucher(input: { code: $code }) {
      valid
      supplements {
        title
        price
        quantity
        bundledItemId
        product_id
      }
      shipping_data
      slug
      remaining_value
    }
  }
`;

export { VALIDATE_VOUCHER };
