import { useMutation, useQuery } from "@apollo/client";
import React, { MutableRefObject, ReactNode, useEffect, useState } from "react";
import {
  APPLY_VOUCHER_CODE,
  ADD_TO_CART,
  EMPTY_CART,
  REMOVE_VOUCHER_CODE,
  UPDATE_CART_ITEM, REMOVE_ITEM_FROM_CART
} from "../mutations";
import FIND_VOUCHER, { Coupon } from "../queries/find-voucher.query.";
import GET_CART, { CartData } from "../queries/get-cart.query";
import { TCart } from "../types/types";
import { getCanton, getZipCode } from "../utils";
import { useCheckZip } from "../hooks";
import ZipcodeSearch from "@stefanzweifel/js-swiss-cantons/src/ZipcodeSearch";

type ShippingPriceType = {
  price: number;
  zipCode: string;
};

type TCartContext = {
  zipCode: string;
  setZipCode: React.Dispatch<string>;
  lastVoucherCode: string;
  loading: boolean;
  setLastVoucherCode: React.Dispatch<string>;
  canton: string;
  setCanton: React.Dispatch<string>;
  shippingCost: ShippingPriceType[];
  setShippingCost: React.Dispatch<ShippingPriceType[]>;
  cart: TCart;
  refetch: () => any;
  addToCart: (id: number, quantity: number, extraData?: any) => Promise<void>;
  checkVoucherCode: (code: string) => any;
  updateCart: (cartUpdate: Partial<TCart>) => void;
  emptyCart: () => Promise<void>;
  removeVoucherCode: (code: string | string[]) => Promise<void>;
  applyVoucherCode: (code: string) => Promise<void>;
  remove: (key: string) => void;
  updateItem: (key: string, quantity: number) => Promise<void>;
  shippingData: any;
  setShippingData: (shippingData: any) => void;
  voucherCode: string;
  setVoucherCode: React.Dispatch<string>;
  removeCartItem: (key: string[]) => Promise<any>;
};

const CartContext = React.createContext<TCartContext>({
  voucherCode: "",
  setVoucherCode: (value: string) => null,
  lastVoucherCode: "",
  setLastVoucherCode: (value: string) => null,
  zipCode: getZipCode,
  setZipCode: (value: string) => null,
  loading: false,
  canton: getCanton,
  setCanton: (value: string) => null,
  shippingCost: null,
  setShippingCost: (value: any) => null,
  cart: {
    total: 0,
    subtotal: 0,
    totalTax: 0,
    shippingTotal: 0,
    products: [],
    appliedCoupons: [],
    zipCode:
      typeof window !== "undefined"
        ? localStorage.getItem("brunchbutler_zipCode")
        : "",
  },
  refetch: () => null,
  updateCart: () => null,
  removeVoucherCode: (code: string | string[]) => Promise.resolve(),
  checkVoucherCode: (code: string) => null,
  emptyCart: () => Promise.resolve(),
  remove: (key: string) => null,
  updateItem: (key: string, quantity: number) => null,
  addToCart: (id: number, quantity: number, extraData?: any) =>
    Promise.resolve(),
  applyVoucherCode: (code: string) => Promise.resolve(),
  shippingData: null,
  setShippingData: (value: any) => null,
  removeCartItem: (key: string[]) => Promise.resolve(),
});

CartContext.displayName = "CartContext";

const useCart: () => TCartContext = () => {
  const ctx = React.useContext(CartContext);
  if (ctx === undefined) {
    throw new Error(`useCart should be used within CartProvider`);
  }
  return ctx;
};

type CartProviderProps = {
  children: ReactNode;
};

const CartProvider: React.FunctionComponent<CartProviderProps> = ({
  children,
}) => {
  const [ref, setRef] = React.useState<
    MutableRefObject<HTMLButtonElement | null>
  >(React.createRef<HTMLButtonElement>());
  const [total, setTotal] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [totalTax, setTotalTax] = useState<number>(0);
  const [shippingTotal, setShippingTotal] = useState<number>(0);
  const [zipCode, setZipCode] = useState<string>(getZipCode ? getZipCode : "");
  const [canton, setCanton] = useState<string>(getCanton ? getCanton : "");
  const [products, setProducts] = useState<any>([]);
  const [appliedCoupons, setAppliedCoupons] = useState<any>([]);
  const [shippingCost, setShippingCost] = useState<any>([]);
  const validateZipCode = useCheckZip();
  const [shippingData, setShippingData] = useState(null);
  const [voucherCode, setVoucherCode] = useState<string>("");
  const [lastVoucherCode, setLastVoucherCode] = useState<string>("");
  const { data, refetch, loading } = useQuery<CartData>(GET_CART, {
    // pollInterval: 25000,
  });

  const [updateCartItem] = useMutation(UPDATE_CART_ITEM);
  const [addToCart] = useMutation(ADD_TO_CART);
  const [emptyCart] = useMutation(EMPTY_CART);
  const [applyVoucher] = useMutation(APPLY_VOUCHER_CODE);
  const [removeVoucher] = useMutation(REMOVE_VOUCHER_CODE);
  const [removeCartItem] = useMutation(REMOVE_ITEM_FROM_CART);

  const { data: voucherData, refetch: fetchVoucher } = useQuery<Coupon>(
    FIND_VOUCHER,
    { variables: { code: "pca7hba7" } }
  );

  const parseAmount = (valueForParse: string) => {
    return +(valueForParse.split("&nbsp;")[1] ?? "0").replace(/[^0-9.]/, "");
  };

  useEffect(() => {
    if (zipCode && zipCode.trim() && zipCode.length === 4) {
      setZipCode(zipCode);
      typeof window !== "undefined" &&
        localStorage.setItem("brunchbutler_zipCode", zipCode);
    } else {
      localStorage.removeItem('brunchbutler_zipCode')
    }
  }, [zipCode]);

  useEffect(() => {
    if (zipCode &&zipCode.trim() && zipCode.length === 4) {
      const zipStore = new ZipcodeSearch();
      const locationData = zipStore.findbyZipcode(zipCode);
      if (locationData && locationData.canton) {
        validateZipCode(zipCode, locationData.canton).then((res) => setShippingData(res));
      }
    }
  }, [zipCode]);

  useEffect(() => {
    refetch()
      .then((responseData) => {
        return responseData.data;
      })
      .then((data) => {
        setAppliedCoupons(data?.cart.appliedCoupons);
        setProducts(data?.cart.contents.products);
        setTotal(parseAmount(data?.cart.total ?? "CHF&nbsp;0.00"));
        setSubtotal(parseAmount(data?.cart.subtotal ?? "CHF&nbsp;0.00"));
        setTotalTax(parseAmount(data?.cart.totalTax ?? "CHF&nbsp;0.00"));
        // setShippingTotal(parseAmount(data?.cart.shippingTotal ?? "CHF&nbsp;0.00"));
      });
  }, [refetch, data]);
  //
  // useEffect(() => {
  //   // if(voucherCode && products.length) {
  //   //   applyVoucher({ variables: { couponCode: voucherCode } }).then((data) => {
  //   //     const cart = data?.data.applyCoupon.cart;
  //   //     setProducts([...cart.contents.products]);
  //   //     setTotal(parseAmount(cart.total ?? "CHF&nbsp;0.00"));
  //   //     setSubtotal(parseAmount(cart.subtotal ?? "CHF&nbsp;0.00"));
  //   //     setTotalTax(parseAmount(cart.totalTax ?? "CHF&nbsp;0.00"));
  //   //     // setShippingTotal(parseAmount(cart.shippingTotal ?? "CHF&nbsp;0.00"));
  //   //     setAppliedCoupons(cart.appliedCoupons);
  //   //   }).then(() => setVoucherCode(""));
  //   //   console.log("apl", data.cart.appliedCoupons);
  //   // }
  // }, [voucherCode, products.length])




  const value = {
    voucherCode,
    setVoucherCode,
    lastVoucherCode,
    setLastVoucherCode,
    zipCode,
    setZipCode,
    setProducts,
    shippingData,
    setShippingData,
    loading,
    canton,
    setCanton,
    shippingCost,
    setShippingCost,
    cart: {
      total,
      shippingTotal,
      totalTax,
      products,
      subtotal,
      appliedCoupons,
      zipCode,
      cartRef: ref,
    },
    refetch: () =>
      refetch().then((data) => {
        setAppliedCoupons(data?.data.cart.appliedCoupons);
        setProducts([...data.data?.cart.contents.products]);
        setTotal(parseAmount(data.data?.cart.total ?? "CHF&nbsp;0.00"));
        setSubtotal(parseAmount(data.data?.cart.subtotal ?? "CHF&nbsp;0.00"));
        setTotalTax(parseAmount(data.data?.cart.totalTax ?? "CHF&nbsp;0.00"));
        // setShippingTotal(parseAmount(data.data?.cart.shippingTotal ?? "CHF&nbsp;0.00"));
      }),

    emptyCart: () => emptyCart().then(() => null),

    removeVoucherCode: (code: string) => {
      return removeVoucher({
        variables: { couponCode: code },
      }).then((data) => {
        const cart = data?.data.removeCoupons.cart;
        setProducts([...cart.contents.products]);
        setTotal(parseAmount(cart.total ?? "CHF&nbsp;0.00"));
        setSubtotal(parseAmount(cart.subtotal ?? "CHF&nbsp;0.00"));
        setTotalTax(parseAmount(cart.totalTax ?? "CHF&nbsp;0.00"));
        // setShippingTotal(parseAmount(cart.shippingTotal ?? "CHF&nbsp;0.00"));
        setAppliedCoupons(cart.appliedCoupons);
      }).then(() => refetch()).then(() => Promise.resolve());
    },
    // updateShippingAddress: () => void;
    addToCart: (id: number, quantity: number, extraData?: any) => {
      console.log("add to cart", id, quantity);
      return addToCart({
        variables: {
          productId: id,
          quantity,
          extraData: extraData ? JSON.stringify(extraData) : JSON.stringify({}),
        },
      })
        .then(() => refetch())
        .then(() => Promise.resolve());
    },

    remove: async (key: string) => {
      await updateCartItem({ variables: { key, quantity: 0 } }).then(() => refetch());
    },

    removeCartItem: async (keys: string[]) => {
      return removeCartItem({ variables: { keys } });
    },

    updateItem: (key: string, quantity: number) => {
      return updateCartItem({ variables: { key, quantity } })
        .then((data2) => data2?.data.updateItemQuantities)
        .then((data2: any) => {
          setAppliedCoupons(data2?.cart.appliedCoupons);
          setProducts([...data2?.cart.contents.products]);
          setTotal(parseAmount(data2?.cart.total ?? "CHF&nbsp;0.00"));
          setSubtotal(parseAmount(data2?.cart.subtotal ?? "CHF&nbsp;0.00"));
          setTotalTax(parseAmount(data2?.cart.totalTax ?? "CHF&nbsp;0.00"));
          // setShippingTotal(parseAmount(data2?.cart.shippingTotal ?? "CHF&nbsp;0.00"));
        });
    },

    applyVoucherCode: (code: string) => {
      return applyVoucher({ variables: { couponCode: code } }).then((data) => {
        const cart = data?.data.applyCoupon.cart;
        setProducts([...cart.contents.products]);
        setTotal(parseAmount(cart.total ?? "CHF&nbsp;0.00"));
        setSubtotal(parseAmount(cart.subtotal ?? "CHF&nbsp;0.00"));
        setTotalTax(parseAmount(cart.totalTax ?? "CHF&nbsp;0.00"));
        // setShippingTotal(parseAmount(cart.shippingTotal ?? "CHF&nbsp;0.00"));
        setAppliedCoupons(cart.appliedCoupons);
      });
    },

    checkVoucherCode: (code: string) => {
      return fetchVoucher({ code })?.then((coupon) => coupon.data);
    },

    updateCart: (cartUpdate: Partial<TCart>) => {
      if (cartUpdate.total) {
        setTotal(cartUpdate.total);
      }
      if (cartUpdate.zipCode) {
        if (typeof window !== "undefined") {
          (window as any).localStorage.setItem(
            "brunchbutler_zipCode",
            cartUpdate.zipCode
          );
        }
        setZipCode(cartUpdate.zipCode);
      }
      if (cartUpdate.totalTax) {
        setTotalTax(cartUpdate.totalTax);
      }
      if (cartUpdate.shippingTotal) {
        setShippingTotal(cartUpdate.shippingTotal);
      }
      if (cartUpdate.subtotal) {
        setSubtotal(cartUpdate.subtotal);
      }

      if (cartUpdate.products) {
        setProducts([...cartUpdate.products]);
      }
    },
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { CartProvider, useCart };

export default useCart;
