import React, { createContext, useEffect } from "react";

export interface UserDataState {
  gender: "Frau" | "Herr";
  firstName: string;
  lastName: string;
  streetName: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface ShippingAddressState {
  firstName: string;
  lastName: string;
  streetName: string;
  houseNumber: string;
  zipCode: string;
  gender: string;
  city: string;
  phoneNumber: string;
}

// firstName: data.billingAddress.firstName,
//   lastName: data.billingAddress.lastName,
//   emailAddress: data.billingAddress.emailAddress,
//   phone: data.billingAddress.phoneNumber,
//   zipCode: data.billingAddress.zipCode,
//   city: data.billingAddress.city,
//   streetName: data.billingAddress.streetName,
//   houseNumber: data.billingAddress.houseNumber,
//   gender: gender,

export type UserDataContextProps = {
  userData: UserDataState[] | Partial<UserDataState>[];
  setUserData: React.Dispatch<React.SetStateAction<UserDataState[]>>;
  shippingAddress: ShippingAddressState[];
  setShippingAddress: React.Dispatch<React.SetStateAction<ShippingAddressState[]>>;
  clearUserData: () => void;
};

const UserDataContext = createContext<UserDataContextProps | null>({
  userData: null,
  setUserData: () => {},
  shippingAddress: null,
  setShippingAddress: () => {},
  clearUserData: () => {}
});

export const useUserData = () => {
  const context = React.useContext(UserDataContext);
  if (context === undefined) {
    throw new Error("useUserData must be used within a UserDataProvider");
  }
  return context;
};

UserDataContext.displayName = "UserDataContext";

const UserDataProvider: React.FC = ({ children }) => {
  const [billingAddress, setBillingAddress] = React.useState<UserDataState[]>([]);
  const [shippingAddress, setShippingAddress] = React.useState<UserDataState[]>([]);

  useEffect(() => {
    const userData =
      typeof window != "undefined" && sessionStorage.getItem("billing");
    const shippingData =
      typeof window != "undefined" && sessionStorage.getItem("shipping");

    if (userData) {
      setBillingAddress(JSON.parse(userData));
    }
    if (shippingData) {
      setShippingAddress(JSON.parse(shippingData));
    }
  }, []);

  useEffect(() => {
    typeof window !== "undefined" &&
      sessionStorage.setItem("billing", JSON.stringify(billingAddress));
    typeof window !== "undefined" &&
      sessionStorage.setItem("shipping", JSON.stringify(shippingAddress));
  }, [billingAddress, shippingAddress]);


  const clearUserData = () => {
    typeof window !== "undefined" && sessionStorage.removeItem("billing");
    typeof window !== "undefined" && sessionStorage.removeItem("shipping");
    setBillingAddress([]);
    setShippingAddress([]);
  }


  const value = {
    userData: billingAddress,
    setUserData: setBillingAddress,
    shippingAddress: shippingAddress,
    setShippingAddress: setShippingAddress,
    clearUserData: clearUserData
  };

  return (
    <UserDataContext.Provider
      value={value}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
