import { gql } from "@apollo/client";

const VALIDATE_ZIP = gql`
  mutation ValidateZip($zip: String!, $state: String!, $week_day: String) {
    validateZip(input: { postcode: $zip, country: "CH", state: $state, week_day: $week_day }) {
     bakery {
       id
       delivery_price
       holidays
       working_days
       working_time
     }
    }
  }
`;

export { VALIDATE_ZIP };
